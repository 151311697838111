import React from "react";

import {ErrorBoundary} from "../../hoc";

const RootWrapper: React.FunctionComponent = ({children}) => {
    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    )
}

export default RootWrapper