import {combineReducers} from 'redux'

import {usersApi} from '../services'
import _setTreeData from './set-tree-data'
import {resourceApi} from "../services/resource.service";
import {methodApi} from "../services/method.service";

const rootReducer = combineReducers({
    setTreeData: _setTreeData,
    [usersApi.reducerPath]: usersApi.reducer,
    [resourceApi.reducerPath]: resourceApi.reducer,
    [methodApi.reducerPath]: methodApi.reducer,
});

export default rootReducer