import {useRef, useState} from "react";
import {Button, Card, Form, FormInstance, Modal, notification, Select, Space, Tree} from 'antd';
import {FolderOpenOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";

import {AppDispatch} from "../../../../store/store";

import FlatArrayGenerator from "../../../common/methods/FlatArrayGenerator";
import useSafeQuery from "../../../common/hook/useSafeQuery";

import {setTree} from "../../../../store/reducers/set-tree-data";
import {useResourceMutationMutation, useResourceQueryQuery} from "../../../../store/services/resource.service";
import {GET_DLAD_REACT_COMPONENT, SET_DALD_PAGE} from "../../../../environments/resource";

const {Option} = Select

let primaryKey = 1
let newArray: Array<any> = []

const openNotificationWithIcon = () => {
    notification["error"]({
        message: 'Error',
        description:
            "You can't extend functional component",
    });
};

const TreeComponent = () => {

    const dispatch: AppDispatch = useDispatch()

    const componentId = useSafeQuery(useResourceQueryQuery, {
        method: SET_DALD_PAGE,
        fields: ["description", "name"],
        filters: []
    })
    const configDocument = useSafeQuery(useResourceQueryQuery, {
        method: GET_DLAD_REACT_COMPONENT,
        fields: [],
        filters: []
    })

    const [getCRI, {data, isLoading,}] = useResourceMutationMutation()


    const formRef = useRef<FormInstance>(null)
    const [treeData, setTreeData] = useState([{
        key: 1,
        title: "Main Layout",
        type: "structural",
        tree_position: "-1",
        icon: <FolderOpenOutlined/>,
        children: []
    },
    ]);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [treeNode, setTreeNode] = useState<boolean | object>({})

    const select = (_e: any, {node}: any) => {
        if (node.type === "functional") {
            openNotificationWithIcon()
        } else {
            setTreeNode(node)
            setIsVisible(true)
        }
    }

    const setChildren = (values: any) => {
        let tree: any = treeNode
        for (const item in values.ps) {
            console.log(values.ps[item])
            tree?.children.push({
                key: ++primaryKey,
                ...values.ps[item],
                component_id: values.ps[item].title,
                tree_position: tree?.key,
                children: []
            })
        }
        setTreeData([...treeData])
        console.log(treeData)
        newArray = FlatArrayGenerator(treeData)
    }

    const handleCancel = () => {
        setIsVisible(false)
    };

    const onFinish = (value: any) => {
        setChildren(value)
        formRef.current!.resetFields()
        setIsVisible(false)
        dispatch(setTree(JSON.parse(JSON.stringify(newArray))))
        newArray = []
    }

    const configDocumentHandler = (event: any) => {
        getCRI({endpoint: event, method: "GET"})
    }

    return (
        <>
            <Space direction={"vertical"}>
                <Tree
                    defaultExpandAll
                    showIcon
                    height={500}
                    treeData={treeData}
                    onSelect={select}
                    style={{fontSize: "x-large", padding: "10px 20px 10px 0", fontWeight: "bold"}}
                />
            </Space>
            <Modal title="Basic Modal" destroyOnClose={true} maskClosable={false} visible={isVisible} footer={null}
                   onCancel={handleCancel}
                   width={850}>
                <Card title="Page Structure" bordered={false} style={{width: 800}}>
                    <Form layout={"vertical"} ref={formRef} onFinish={onFinish}>
                        <Form.List name={"ps"}>
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map((Field, index) => (
                                        <Space align={"baseline"} key={index}>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "type"]}
                                                fieldKey={[Field.fieldKey, "type"]}
                                            >
                                                <Select placeholder={"Select Type"} allowClear>
                                                    <Option value={"functional"}>Functional</Option>
                                                    <Option value={"structural"}>Structural</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "title"]}
                                                fieldKey={[Field.fieldKey, "component_id"]}
                                            >
                                                <Select placeholder={"Select Type"} allowClear>
                                                    {componentId.data ? componentId.data?.data.map((item: any, index: number) => {
                                                        return <Option key={index}
                                                                       value={item.name}>{item.name}</Option>
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "config_doc_type"]}
                                                fieldKey={[Field.fieldKey, "config_doc_type"]}
                                            >
                                                <Select placeholder={"Config Document"} onChange={configDocumentHandler}
                                                        allowClear>
                                                    {configDocument.data ? configDocument.data.data.map((item: any, index: number) => {
                                                        if (item.name.includes(" C ")) {
                                                            return <Option key={index}
                                                                           value={item.name}>{item.name}</Option>
                                                        }
                                                        return null
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "config_ref_id"]}
                                                fieldKey={[Field.fieldKey, "config_ref_id"]}
                                            >
                                                <Select placeholder={"Config Ref Id"} allowClear>
                                                    {data ? data?.data.map((item: any, index: number) => {
                                                        return <Option key={index}
                                                                       value={item.name}>{item.name}</Option>
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(Field.name)}/>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </>
    )
}

export default TreeComponent