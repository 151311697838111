import {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Form, Modal, notification, Row, Select, Skeleton, Space, Table, Tree} from "antd";
import {DownOutlined, FolderOpenOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

import useSafeQuery from "../../../common/hook/useSafeQuery";

import GetNestedChildren from "../../../common/methods/GetNestedChildren";
import DeleteNode from "../../../common/methods/DeleteNode";

import {ITreeData, ITreeNode} from "../../../common/interface/Interface";

import {useResourceMutationMutation, useResourceQueryQuery} from "../../../../store/services/resource.service";
import {GET_CONFIG_DOCUMENT, GET_DLAD_REACT_COMPONENT, SET_DALD_PAGE} from "../../../../environments/resource";

const {Option} = Select

let tableData: Array<any> = [];
let newArray: Array<any> = []

const openNotificationWithIcon = () => {
    notification["error"]({
        message: 'Error',
        description:
            "You can't extend functional component",
    });
};

const UpdateComponent = () => {

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const treeNode = useRef<ITreeNode>()
    const [treeData, setTreeData] = useState<Array<ITreeData>>([
        {
            title: 'Page Layout',
            key: 1,
            icon: <FolderOpenOutlined/>,
            children: []
        },
    ])

    const dladPage = useSafeQuery(useResourceQueryQuery, {
        method: SET_DALD_PAGE,
        fields: ["description", "name"],
        filters: []
    })

    const componentId = useSafeQuery(useResourceQueryQuery, {
        method: GET_DLAD_REACT_COMPONENT,
        fields: [],
        filters: []
    })

    const configDocument = useSafeQuery(useResourceQueryQuery, {
        method: GET_CONFIG_DOCUMENT,
        fields: [],
        filters: [["DocType", "module", "=", "UI"]]
    })

    const [getPage, {data, isLoading}] = useResourceMutationMutation()
    const [getCRI, selectOption] = useResourceMutationMutation()

    if (dladPage.isSuccess) {
        tableData = dladPage.data?.data.map((item: any, index: number) => ({
            key: Math.random(),
            name: item?.name,
            description: item?.description,
        }))
    }

    useEffect(() => {
        if (data) {
            const modal: Array<any> = data?.data?.components.map((item: object, index: number) => {
                const {
                    component_id,
                    config_doc_type,
                    config_ref_id,
                    key,
                    tree_position,
                    type
                } = data?.data?.components[index]
                return {component_id, config_doc_type, config_ref_id, key, tree_position, type}
            })

            let tree = [{
                title: 'Page Layout',
                key: 1,
                icon: <FolderOpenOutlined/>,
                children: GetNestedChildren(modal, 1)
            },]
            setTreeData(tree)
        }
    }, [data])

    const columns: Array<any> = [
        {
            title: 'Description',
            width: 100,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Name',
            width: 100,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'operation',
            width: 100,
            render: (record: any) => <Button
                onClick={() => getPage({endpoint: SET_DALD_PAGE + "/" + record?.name, method: "GET"})}>Update</Button>,
        },
    ];

    const handleCancel = () => {
        setIsVisible(false)
    };

    const select = (_e: any, {selectedNodes, node, event}: any) => {
        treeNode.current = node
        setIsVisible(true)
    }

    const configDocumentHandler = (event: any) => {
        getCRI({endpoint: event, method: "GET"})
    }

    const setChildren = (values: any) => {
        for (const item in values.ps) {

            treeNode.current?.children.push({
                key: Math.random(),
                ...values.ps[item],
                component_id: values.ps[item].title,
                tree_position: treeNode.current?.key,
                children: [] as any
            })
        }
        setTreeData([...treeData])
        // newArray = FlatArrayGenerator(treeData)
    }

    const onFinish = (value: any) => {
        setChildren(value)
        setIsVisible(false)
        // console.log("**************************", newArray)
    }

    const deleteNodeHandler = () => {
        DeleteNode(treeData, treeNode.current?.key)
        setTreeData([...treeData])
        setIsVisible(false)
    }

    return (
        <>
            {dladPage.isLoading ? <Skeleton/> :
                <Row>
                    <Col md={24} lg={16} style={{padding: "10px"}}>
                        <Card title="Default size card">
                            <Table columns={columns} dataSource={tableData} scroll={{x: 600, y: 300}}/>
                        </Card>
                    </Col>
                    <Col md={24} lg={6} style={{padding: "10px"}}>
                        <Card title="Default size card">
                            {isLoading ? <Skeleton/> :
                                <Tree
                                    showIcon
                                    defaultExpandAll={true}
                                    defaultSelectedKeys={['0-0-0']}
                                    switcherIcon={<DownOutlined/>}
                                    treeData={treeData}
                                    onSelect={select}
                                    style={{fontSize: "large"}}
                                />}
                        </Card>
                    </Col>
                </Row>
            }
            <Modal title="Basic Modal" destroyOnClose={true} maskClosable={false} visible={isVisible} footer={null}
                   onCancel={handleCancel}
                   width={850}>
                <Card title="Page Structure" bordered={false} style={{width: 800}}>

                    <Space><h3>You can delete ${treeNode.current?.title} component <Button onClick={deleteNodeHandler} type={"primary"} size={"small"} danger style={{margin: "10px"}}>Delete</Button></h3>
                    </Space>

                    <Form layout={"vertical"} onFinish={onFinish}>
                        <Form.List name={"ps"}>
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map((Field, index) => (
                                        <Space align={"baseline"} key={index}>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "type"]}
                                                fieldKey={[Field.fieldKey, "type"]}
                                            >
                                                <Select placeholder={"Select Type"} allowClear>
                                                    <Option value={"functional"}>Functional</Option>
                                                    <Option value={"structural"}>Structural</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "title"]}
                                                fieldKey={[Field.fieldKey, "component_id"]}
                                            >
                                                <Select placeholder={"Select Type"} allowClear>
                                                    {componentId.data ? componentId.data?.data.map((item: any, index: number) => {
                                                        return <Option key={index}
                                                                       value={item.name}>{item.name}</Option>
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "config_doc_type"]}
                                                fieldKey={[Field.fieldKey, "config_doc_type"]}
                                            >
                                                <Select placeholder={"Config Document"}
                                                        onChange={configDocumentHandler}
                                                        allowClear>
                                                    {configDocument.data ? configDocument.data.data.map((item: any, index: number) => {
                                                        if (item.name.includes(" C ")) {
                                                            return <Option key={index}
                                                                           value={item.name}>{item.name}</Option>
                                                        }
                                                        return null
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...Field}
                                                name={[Field.name, "config_ref_id"]}
                                                fieldKey={[Field.fieldKey, "config_ref_id"]}
                                            >
                                                <Select placeholder={"Config Ref Id"} allowClear>
                                                    {selectOption.data ? selectOption.data?.data.map((item: any, index: number) => {
                                                        return <Option key={index}
                                                                       value={item.name}>{item.name}</Option>
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(Field.name)}/>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}
                                                disabled={treeNode.current?.type === "functional"}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={treeNode.current?.type === "functional"}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
        </>
    )
}

export default UpdateComponent