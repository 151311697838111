import {useReducer} from "react";

const useModel = () => {
    const [model, setFormData] = useReducer((state: any, newState: any) => ({...state, ...newState}), {})

    const handleOnChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({[name]: value})
    };

    return [model, handleOnChange]
}

export default useModel