const FlatArrayGenerator = (array: Array<any>): Array<any> => {
    let newArray: Array<any> = []
    const GetFlatArray = (array: Array<any>): void => {

        array.forEach((item) => {
            if (item.children.length === 0) {
                newArray.push(item)
            } else {
                newArray.push({
                    "key": item?.key,
                    "type": item?.type,
                    "component_id": item?.title,
                    "config_doc_type": item?.config_doc_type,
                    "config_ref_id": item?.config_ref_id,
                    "tree_position": item?.tree_position
                })
                GetFlatArray(item.children)
            }
        })
    }
    GetFlatArray(array)
    return newArray
}

export default FlatArrayGenerator