import {Col, Row} from "antd";

const HeaderComponent = () => {

    return (
        <>
            <Row style={{backgroundColor: "#004b8c"}}>
                <Col flex={"200px"}>
                    <h1 style={{textAlign: "center"}}>Logo</h1>
                </Col>
                <Col flex={"auto"}>
                    <h1>Content</h1>
                </Col>
            </Row>
        </>
    )
}

export default HeaderComponent