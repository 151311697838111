import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Col, Form, Input, Row} from 'antd';

import useModel from "../../../common/hook/useModel";
import {useLoginMutation} from "../../../../store/services";
import Title from "antd/es/typography/Title";

const LoginComponent = () => {
    const history = useHistory()
    const [model, handleOnChange] = useModel()

    const [login, {data}] = useLoginMutation()

    useEffect(() => {
        if (data?.isLoggedIn)
            history.push("app/dashboard")
    }, [data])

    const loginHandler = async () => {
        console.log(model)
        try {
            await login(model).unwrap()
        } catch {
            alert("login fail")
        }
    }

    return (
        <>
            <Row>
                <Col span={12} className={"mainContainer leftCol"}>
                    <Title>ERP Dashboard</Title>
                </Col>
                <Col span={12} >
                    <div className={"mainContainer"}>
                        <Form
                            name="basic"
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            initialValues={{remember: true}}
                            autoComplete="off"
                            className={"form"}
                        >
                            <Form.Item
                                label="Username"
                                name="usr"
                                rules={[{required: true, message: 'Please input your username!'}]}
                            >
                                <Input name="usr" onChange={handleOnChange}/>
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="pwd"
                                rules={[{required: true, message: 'Please input your password!'}]}
                            >
                                <Input.Password name="pwd" onChange={handleOnChange}/>
                            </Form.Item>

                            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                <Button type="primary" htmlType="submit" onClick={loginHandler}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default LoginComponent
