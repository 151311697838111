import { Login, Dashboard, Update } from "../modules/components/pages";
import { RootAppComponentWithRoutes } from "../modules/routes/routes.component";

const Routes = [
    { key: "ROOT", path: "/", title: "Login", component: Login, exact: true },
    {
        key: "APP_ROOT",
        path: "/app",
        title: "App",
        component: RootAppComponentWithRoutes,
        routes: [
            {
                key: "APP_Dashboard",
                path: "/app/dashboard",
                title: "Dashboard",
                component: Dashboard,
                exact: true,
            },
            {
                key: "APP_Update",
                path: "/app/update",
                title: "Update",
                component: Update,
                exact: true,
            },
        ],
    },
];

export default Routes;
