import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import {BASE_URL} from "../../environments/enviroment";

export const methodApi = createApi({
    reducerPath: 'methodApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/method/",
        credentials: 'include'
    }),
    endpoints: builde => ({
        getPage: builde.mutation<any, any>({
            query: (payload) => ({
                url: payload.endpoint,
                method: payload.method,
                body: payload.data,
            }),
        }),
    })
})

export const {useGetPageMutation} = methodApi