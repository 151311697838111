import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {BASE_URL} from "../../environments/enviroment";

export const resourceApi = createApi({
    reducerPath: 'resourceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + "api/resource/",
        credentials: "include"
    }),
    endpoints: build => ({
        resourceQuery: build.query<any, any>({
            query: ({
                        method,
                        fields = [],
                        filters = []
                    }) => method + '?fields=' + JSON.stringify(fields) + '&filters=' + JSON.stringify(filters)
        }),
        resourceMutation: build.mutation<any, any>({
            query: ({endpoint, method, payload}) => ({
                url: endpoint,
                method: method,
                body: payload
            })
        }),
    })
})

export const {useResourceQueryQuery, useResourceMutationMutation} = resourceApi