import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IModalState {
    treeData: object,
}

const initialState: IModalState = {
    treeData: {},
}

const modalSlice = createSlice({
    name: "treeData",
    initialState,
    reducers: {
        setTree: {
            reducer: (state, action: PayloadAction<object>) => {
                state.treeData = action.payload
            },
            prepare: (model: object) => {
                return {payload: model}
            }
        }
    }
})

export const { setTree } = modalSlice.actions
export default modalSlice.reducer