import React, {Fragment} from 'react';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";

import 'antd/dist/antd.css';
import './styles/index.scss';

import {store} from "./store/store";
import RootWrapper from "./modules/wrappers/root-wrapper/root-wrapper.component";
import {RootRouteWithSubRoutes} from "./modules/routes/routes.component";
import Routes from './configs/router-configs'

function App() {
    return (
        <Fragment>
            <Provider store={store}>
                <Router>
                    <RootWrapper>
                        <RootRouteWithSubRoutes routes={Routes}/>
                    </RootWrapper>
                </Router>
            </Provider>
        </Fragment>
    );
}

export default App;
