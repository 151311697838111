import {Switch, Route} from 'react-router-dom';
import { AppRouteWrapper } from '../wrappers';
import { PageNotFound } from '../components/pages'

/**
 * Render root of router stuff
 */
export const RootRouteWithSubRoutes = ({routes}: any) => {
    return (
        <Switch>
            {routes.map((route: any, i: any) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
            <Route component={PageNotFound}/>
        </Switch>
    );
};

/**
 * Specific top level route component with app wrapper
 */
export const RootAppComponentWithRoutes = ({routes}: any) => {
    return (
        <AppRouteWrapper>
            <RootRouteWithSubRoutes routes={routes}/>
        </AppRouteWrapper>
    );
};

/**
 * Render a route that has sub routes
 */
const RouteWithSubRoutes = (route: any) => {
    const config = {...route};
    delete config.component;

    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props: any) => <route.component {...props} config={config} routes={route.routes}/>}
        />
    );
};
