const DeleteNode = (array: Array<any>, key: any) => {
    console.log("array======>", array, "key======>", key)
    for(let i=0;i<array.length;i++) {
        if(array[i].key == key){

            if ( array[i].key == key) {
                console.log("found+++++++++++++++++++", array[i].key)
                array.splice(i, 1);
            }

        }else {
            if(array[i].children.length > 0) {
                DeleteNode(array[i].children, key)
            }
        }
    }
}

export default DeleteNode