
const NotFoundComponent = () => {
    return (
        <>
            <div>
                <h1>!Page Not Found</h1>
            </div>
        </>
    )
}

export default NotFoundComponent