const GetNestedChildren = (modals: Array<any>, parentId: any): Array<any> => {
    let nestedTreeStructure: Array<any> = [];
    let length = modals.length;

    for (let i = 0; i < length; i++) {
        let modal = modals[i];
        modal["title"] = modals[i].component_id
        if (modal.tree_position == parentId) {
            let children = GetNestedChildren(modals, modal.key);

            if (children.length > 0) {
                modal.children = children;
            } else {
                modal.children = []
            }
            nestedTreeStructure.push(modal);
        }
    }

    return nestedTreeStructure
}

export default GetNestedChildren