import {Button, Card, Form, Input, Row, Select, Space, Col} from "antd";
import {useSelector} from "react-redux";

import {RootState} from "../../../../store/store";
import TreeComponent from "./tree.component";
import {useResourceMutationMutation} from "../../../../store/services/resource.service";
import {SET_DALD_PAGE} from "../../../../environments/resource";

const {Option} = Select

const DashboardComponent = () => {

    const [createPage, {isLoading, error}] = useResourceMutationMutation()

    if (error) {
        console.log(error)
    }

    const treeData = JSON.parse(JSON.stringify(useSelector<RootState, any>(
        state => state.setTreeData.treeData
    )))

    const onFinish = (value: object) => {
        // console.log("************",treeData[0].component_id)
        delete treeData[0].component_id
        console.log(JSON.stringify({...value, "components": [...treeData]}))
        let payload = JSON.stringify({...value, "components": [...treeData]})
        createPage({endpoint: SET_DALD_PAGE, method: "POST", payload: payload})
    }

    const sele = (value: any) => {
        console.log(value)
    }

    return (
        <Card style={{height: "100vh"}}>
            <Row>
                <Col md={24} lg={12} style={{padding: "10px", backgroundColor: "azure"}}>
                    <Space direction="vertical">
                        <Card title="Page Type" bordered={false} style={{width: 400}}>
                            <Form
                                layout={"vertical"}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label={"Type"}
                                    name={"type"}
                                >
                                    <Select placeholder={"Select Type"} onChange={sele} allowClear>
                                        <Option value={"doc"}>Doc</Option>
                                        <Option value={"dashboard"}>Dashboard</Option>
                                        <Option value={"list"}>List</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={"Description"}
                                    name={"description"}
                                >
                                    <Input placeholder="Enter description"/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Space>
                </Col>
                <Col  md={24} lg={12} style={{padding: "10px"}}>
                    <TreeComponent/>
                </Col>
            </Row>
        </Card>
    )
}

export default DashboardComponent