import {Layout} from 'antd';

import {HeaderComponent, SideBarMenu} from "../../../common/component";

const MainLayoutComponent = ({children}: any) => {

    const {Header, Footer, Sider, Content} = Layout;

    return (
        <>
            <Layout style={{height: '100vh', overflow: 'hidden'}}>
                <Header style={{padding: 0}}>
                    <HeaderComponent/>
                </Header>
                <Layout>
                    <Sider>
                        <SideBarMenu/>
                    </Sider>
                    <Layout>
                        <Content style={{overflow: "auto", padding: "10px"}}>
                            {children}
                        </Content>
                        <Footer>Footer</Footer>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default MainLayoutComponent