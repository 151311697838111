import {useRef} from "react";

const useSafeQuery = (query: any, method: any) => {
    let skipQuery = useRef(false)

    let result = query(method, {skip: skipQuery.current})

    if (result.isError) {
        skipQuery.current = true
    } else {
        skipQuery.current = false
    }

    return result
}

export default useSafeQuery