import React from "react";
import {Redirect} from "react-router-dom";
import {useUserStateQuery} from "../../../store/services";

import MainLayout from "../../components/layout/main-layout/main-layout.component";

const GuardWrapper: React.FunctionComponent = ({children}) => {

    const {data, error, isLoading, isSuccess} = useUserStateQuery('')
    let isAuthenticated = false;

    if (isLoading) {
        return <h1>Loading ....</h1>
    }

    if (error) {
        return <Redirect to={"/"}/>
    }

    if (data) {
        isAuthenticated = true
    }

    return (
        <>
            {isAuthenticated ? (<MainLayout>{children}</MainLayout>) : (
                <Redirect to={"/"}/>
            )}
        </>
    );
};

export default GuardWrapper;
